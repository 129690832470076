import { Box, Button, Center, Flex, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { MdKeyboardArrowRight } from "react-icons/md";
import WithSubnavigation from "../components/Header";
import stores from "../data/ca-stores.json";


const IndexPage = () => {
  const [provinceStores, setProvinceStores] = useState([]);
  const order = ["B", "A", "O", "M"]
  const provinces = new Set(stores
    .map((item) => item.province)
  )

  const displayTitle = provinceStores.length > 0;

  const onStoreSelect = (selectedProvince) => {
    const localStores = stores
      .filter((item) => item.province === selectedProvince)
    setProvinceStores(localStores);
  };
  return (
    <>
      <WithSubnavigation />
      {!displayTitle && <Box as="main" h="100%">
        <Center mt="20">
          <Heading fontFamily="Khula" fontWeight={400}>
            Select your province
          </Heading>
        </Center>
        <Center mt="10">
          <Flex maxWidth={["500px", "700px"]} m="auto" alignItems={{ base: "center" }} justifyContent="space-between" w="100%" direction={{ base: "column", md: "row" }}>
            {Array.from(provinces)
              .sort((a, b) => order.indexOf(a.charAt(0)) - order.indexOf(b.charAt(0)))
              .map((province) => (
                <Button
                  my={[3, 1]}
                  mx={["auto", 2]}
                  w={["300px", "180px"]}
                  fontWeight={600}
                  color={"white"}
                  bg={"blackAlpha.900"}
                  href={"https://baileynelson.com/pages/eye-exams"}
                  _hover={{
                    color: "white",
                    bg: "blackAlpha.800",
                  }}
                  borderRadius="0"
                  onClick={() => onStoreSelect(province)}
                  h="12"
                >
                  <Text fontWeight="bold" textAlign="center" justifyContent="start" lineHeight={1.1}>{province}</Text>
                </Button>
              ))}
          </Flex>
        </Center>
      </Box>}
      <Box>
        <VStack mt="5" mb="10" spacing={6}>
          {provinceStores
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((store) => (
              <Box
                w="90%"
                p={3}
                borderRadius="3xl"
                maxWidth="400px"
                boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;"
                _hover={{
                  boxShadow: " rgba(0, 0, 0, 0.3) 0px 8px 16px -8px, rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,",
                }}
              >
                <Link to={store.gmaps}>
                  <Flex justifyContent="space-between" h="100%" align="center">
                    <Icon as={FcGoogle} fontSize="40" mr="3" />
                    <Text fontWeight="bold" textAlign="center" justifyContent="start" lineHeight={1.1}>{store.title}</Text>
                    <Icon as={MdKeyboardArrowRight} fontSize="40" />
                  </Flex>
                </Link>
              </Box>
            ))}
        </VStack>
        {displayTitle && <Center mt="5">
          <Button onClick={() => setProvinceStores([])} width="100px" mb="10" size="lg">Back</Button>
        </Center>}
      </Box>
    </>
  );
};

export default IndexPage;

